<template>
  <div class="page-list" v-show="pageListShow">
    <div class="pagetion">
      <div class="pagetion-content" v-if="showOptions.leftOptions[0].checked">
        <div class="pagetion-content-show" v-for="(item, idx) in pageListData" :key="item.id">
          <div
            @click="changeSelectIdx(idx)"
            :class="selectIdx == idx ? 'pagetion-item-select pagetion-item' : 'pagetion-item'"
          >
            {{ idx + 1 }}
          </div>
          <div class="pagetion-item-label" v-if="idx < pageListData.length - 1">●</div>
        </div>
      </div>
      <div class="pagetion-content" v-if="showOptions.leftOptions.length > 1 && showOptions.leftOptions[1].checked">
        <div class="pagetion-content-show" v-for="(item, idx) in jpgListData" :key="item.id">
          <div
            @click="changeSelectIdx(idx)"
            :class="selectIdx == idx ? 'pagetion-item-select pagetion-item' : 'pagetion-item'"
          >
            {{ idx + 1 }}
          </div>
          <div class="pagetion-item-label" v-if="idx < jpgListData.length - 1">●</div>
        </div>
      </div>
    </div>
    <div class="list-slider">
      <div class="list-title" v-if="taskDetail.productionName">
        <a-tooltip>
          <template slot="title">
            <div>{{ taskDetail.productSequenceNo + '-' + taskDetail.productionName }}</div>
          </template>
          <div>{{ taskDetail.productSequenceNo + '-' + taskDetail.productionName }}</div>
        </a-tooltip>
        <div style="padding-top:5px">
          <a-select
            @change="chapterChange"
            option-filter-prop="label"
            style="width:100%"
            v-model="chapterId"
            show-search
            placeholder="请选择章节"
          >
            <a-select-option
              v-for="chapter in chapterList"
              :key="chapter.chapterId"
              :label="chapter.label"
              :value="chapter.chapterId"
            >
              {{ chapter.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div>
        <a-slider :default-value="100" :max="100" :min="10" @change="handledChange" />
      </div>
    </div>
    <div class="list-content" v-if="showOptions.leftOptions[0].checked">
      <div
        :class="selectIdx == idx ? 'page-item-select page-item' : 'page-item'"
        v-for="(item, idx) in pageListData"
        :key="item.id"
      >
        <img
          @click="e => changeShowScroll(e, idx)"
          :src="item.filePath"
          :ref="item.id + 'pageImg'"
          :style="'width:' + sliderValue + '%;height:auto'"
        />
        <div style="word-wrap: break-word;white-space: pre-line;">{{ item.fileName }}</div>
      </div>
    </div>
    <div class="list-content" v-if="showOptions.leftOptions.length > 1 && showOptions.leftOptions[1].checked">
      <div
        :class="selectIdx == idx ? 'page-item-select page-item' : 'page-item'"
        v-for="(item, idx) in jpgListData"
        :key="item.id"
      >
        <img
          @click="e => changeShowScroll(e, idx)"
          :src="item.filePath"
          :ref="item.id + 'pageImg'"
          :style="'width:' + sliderValue + '%;height:auto;'"
        />
        <div style="word-wrap: break-word;white-space: pre-line;">{{ item.fileName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageListData: {
      type: Array,
      default: () => []
    },
    jpgListData: {
      type: Array,
      default: () => []
    },
    pageListPostions: {
      type: Array,
      default: () => []
    },
    chapterList: {
      type: Array,
      default: () => []
    },
    taskDetail: {
      type: Object,
      default: () => ({})
    },
    showOptions: {
      type: Object,
      default: () => ({})
    },
    selectIdx: {
      type: Number,
      default: 0
    },
    pageListShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sliderValue: 100,
      scrollTop: 0,
      pageScrollHeight: 0,
      chapterId: undefined
    }
  },
  watch: {
    selectIdx() {
      if (this.pageListPostions.find(item => item.idx == this.selectIdx)) {
        document.getElementsByClassName('list-content')[0].scrollTop =
          this.pageListPostions.find(item => item.idx == this.selectIdx).top - 251 + this.pageScrollHeight
        document.getElementsByClassName('pagetion-content')[0].scrollTop = document.getElementsByClassName(
          'pagetion-content-show'
        )[this.selectIdx].offsetTop
      }
    }
  },
  methods: {
    chapterChange() {
      localStorage.setItem('translate_download', '')
      let chapter = this.chapterList.find(c => c.chapterId == this.chapterId)
      window.open(`/translate/${chapter.taskId}/${chapter.chapterId}/0/0`)
      this.chapterId = this.taskDetail.chapterId
    },
    handledChange(value) {
      this.sliderValue = value
      this.$nextTick(() => {
        this.$emit('getPageListPostions')
      })
    },
    changeSelectIdx(idx) {
      this.pageScrollHeight = 0
      this.$emit('changeSelectIdx', idx)
      this.$emit('changePage')
    },
    changeShowScroll(e, idx) {
      this.pageScrollHeight = e.offsetY
      this.$emit('changeShowScroll', e, idx)
      this.$emit('changePage')
    },
    allUpload() {
      this.$emit('getPageListPostions')
    },
    loadImg() {
      let loadImgs = []
      this.pageListData.forEach(item => {
        let that = this
        var img = that.$refs[item.id + 'pageImg'][0]
        img.onload = function() {
          loadImgs.push(img)
          if (loadImgs.length == that.pageListData.length) {
            that.allUpload()
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.page-list {
  padding: 12px 0 12px 24px;
  height: 100%;
  position: relative;
  .pagetion {
    position: absolute;
    width: 24px;
    right: 0;
    top: 100px;
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list-title {
    > div {
      font-size: 14px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 26px;
    }
  }
  .list-slider {
    height: 100px;
    padding-right: 24px;
  }
  .list-content {
    height: calc(100% - 100px);
    padding: 2px 24px 6px 2px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
::-webkit-scrollbar-button {
  height: 0;
}
::-webkit-scrollbar {
  border-radius: 0;
  position: relative;
  width: 3px;
}
::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #fff;
}
::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
.page-item {
  text-align: center;
  font-size: 14px;
  line-height: 32px;
  position: relative;
  cursor: crosshair;
  img {
    border: 1px solid #c1c1c1;
  }
  > div {
    min-height: 30px;
    line-height: 30px;
  }
}
.page-item-select {
  img {
    border: 3px solid var(--theme-color);
  }
}
.pagetion-item {
  cursor: pointer;
  text-align: center;
  width: 100%;
  &:hover {
    color: var(--theme-color);
  }
}
.pagetion-content-show {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.pagetion-content {
  max-height: 50%;
  overflow-y: auto;
  position: relative;
  overflow-x:hidden ;
  &::-webkit-scrollbar {
    width: 0px;
  }
}
.pagetion-item-select {
  color: var(--theme-color);
  font-weight: 700;
  transition: 0.3s;
}
.pagetion-item-label {
  user-select: none;
  text-align: center;
  transform: scale(0.5);
}
@media screen and (max-width: 1450px) {
  .list-title {
    > div {
      font-size: 12px !important;
      line-height: 20px !important;
    }
  }
  .list-slider {
    height: 50px;
  }
  .list-content {
    font-size: 12px;
  }
}
@media screen and (max-width: 900px) {
  .list-title {
    > div {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
  .list-slider {
    height: 40px;
  }
  .ant-slider {
    display: none;
  }
  .list-content {
    font-size: 12px;
  }
}
@media screen and (max-width: 780px) {
  .list-slider {
    display: none;
  }
  .list-content {
    color: #fff;
  }
}
</style>
